<template>
  <div
    :is="asLink ? 'router-link' : 'div'"
    :to="asLink ? { path: isCollegiate ? `/collegiate/team/${id}` : `/team/${id}`, ...to } : undefined"
    class="team-cell"
    @[asLink?`click.native`:`click`]="trackClick"
  >
    <ImgCell :image="fallbackedImage" :label="name" :variant="variant" :size="size" />
  </div>
</template>

<script>
import px from 'vue-types'

import ImgCell from '@/components/generic/ImgCell.vue'
import mixpanel from '@/mixpanel'

export default {
  name: 'TeamCell',
  components: { ImgCell },
  props: {
    id: px.string,
    logo_url: px.string,
    image: px.string,
    link: px.bool.def(false),
    name: px.string,
    size: px.string.def(undefined),
    trackingContext: px.object,
    variant: px.oneOf(['col', 'row', 'row-reverse']).def('col'),
    to: px.object,
    isCollegiate: px.bool.def(false),
  },
  computed: {
    asLink() {
      return this.link && this.id
    },
    computedTrackingContext() {
      return {
        ...this.trackingContext,
        teamId: this.id,
        teamName: this.name,
      }
    },
    fallbackedImage() {
      return this.image || this.logo_url
    },
  },
  methods: {
    trackClick($event) {
      if (this.asLink) {
        mixpanel.track_link({ type: 'team', href: $event.target.href }, this.computedTrackingContext)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.team-cell {
  display: block;
}
</style>
